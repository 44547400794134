import appendQuery from 'append-query'
import attributionTemplate from './attribution'
import mediaButtonTemplate from './media-button'
import pauseTemplate from './pause'
import css from './footer.css'

export const GRID_HITTING_HEADER_ID = 'grid-hitting-header-marker'
export const GRID_ACTIVE_MARKER_ID = 'grid-active-marker'
export const GRID_BUTTON_ACTIVE_ID = 'grid-button-active-marker'
export const GRID_HEADER_ID = 'grid-header'

const footerMobile = ({ url, external_media }, { hideVideo, trackingQueryString }) =>
    `<div class="${css.footerMobile}" data-footer>
        <div class="${css.mobileButton}">
            ${hideVideo ? '' : mediaButtonTemplate(external_media, true)}
        </div>
        <a class="${css.mobileGiphyButton}"
            href="${appendQuery(url, trackingQueryString)}"
            target="_blank"
            data-haslabel>
            <div class="${css.mobileGiphyLogo}">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 18">
                    <g fill="none">
                        <path fill="#0CF" d="M12 16H0v2h14v-2"/>
                        <path fill="#0F9" d="M0 2v14h2V2"/>
                        <path fill="#FFF35C" d="M6.21 0H0v2h8V0"/>
                        <path fill="#F66" d="M12 4V2h-2V0H8v6h6V4"/>
                        <path fill="#93F" d="M12 6v10h2V6"/>
                        <path fill="#000" d="M6 2h2V0m6 6h-2v2" opacity=".4"/>
                    </g>
                </svg>
            </div>
            <div data-label>View on GIPHY</div>
        </a>
    </div>`

const gifClickArea = (url, trackingQueryString) => `<a class="${css.gifClickArea}"
    href="${appendQuery(url, trackingQueryString)}"
    target="_blank"
    data-gif></a>`

const footerDesktop = ({ user, external_media, url }, { hideVideo, isTwitter, trackingQueryString }) => {
    const attribution = attributionTemplate(user, trackingQueryString)
    const hasAttribution = attribution.length > 0
    return `<div class="${css.container}" id="footer-container">
                ${gifClickArea(url, trackingQueryString)}
                <div class="${css.containerInner}" data-footer>
                    <div class="${css.gridHittingHeaderMarker}" id="${GRID_HITTING_HEADER_ID}"></div>
                    <div class="${css.gridActiveMarker}" id="${GRID_ACTIVE_MARKER_ID}"></div>
                    <div class="${css.gridButtonMarker}" id="${GRID_BUTTON_ACTIVE_ID}"></div>
                    <div class="${css.footer}">
                        <div class="${css.relatedHeader}" id="${GRID_HEADER_ID}"><span>Related Gifs</span></div>
                        ${pauseTemplate(isTwitter, hasAttribution)}
                        ${attribution}
                        ${hideVideo ? '' : mediaButtonTemplate(external_media || {})}
                    </div>
                    <div class="${css.grid}" id="grid"></div>
                </div>
            </div>`
}

export default function (gif, data) {
    return global.isMobile ? footerMobile(gif, data) : footerDesktop(gif, data)
}
