exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2XBDTIVigBJDybhZvL-hU3 {\n    display: block;\n    height: 100%;\n    position: relative;\n    width: 100%;\n}\n\n._3aRLlClO7wY2OiqMkwRcGP {\n    background-position: center center;\n    background-repeat: no-repeat;\n    background-size: contain;\n    display: none;\n    left: 0;\n    position: absolute;\n    pointer-events: none;\n    top: 0;\n}\n\nimg[data-paused=\"true\"] + ._3aRLlClO7wY2OiqMkwRcGP {\n        display: block;\n    }\n\n._3vYn8QjoEvrXxHyqdn9ddZ {\n    pointer-events: none;\n}\n\n.nlSABoG9CSaJpsufv8WW9 {\n    -o-object-fit: contain;\n       object-fit: contain;\n}\n\n.nlSABoG9CSaJpsufv8WW9[data-paused=\"true\"] {\n        display: none;\n    }", ""]);

// exports
exports.locals = {
	"container": "_2XBDTIVigBJDybhZvL-hU3",
	"still": "_3aRLlClO7wY2OiqMkwRcGP _2XBDTIVigBJDybhZvL-hU3",
	"video": "_3vYn8QjoEvrXxHyqdn9ddZ _2XBDTIVigBJDybhZvL-hU3",
	"image": "nlSABoG9CSaJpsufv8WW9 _3vYn8QjoEvrXxHyqdn9ddZ _2XBDTIVigBJDybhZvL-hU3"
};