exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css"), undefined);

// module
exports.push([module.id, "._2Whr4fsCK_L_ucjcRRGEh_ {\n    background: " + require("-!../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css").locals["giphyNewBlack"] + ";\n    height: 100%;\n    left: 0;\n    pointer-events: none;\n    position: absolute;\n    top: 0;\n    width: 100%;\n    transition: opacity .3s ease-out;\n}\n._2b13JmVbRmen7ljiw3f1mS {\n    background-color: #222;\n    background-image: linear-gradient(45deg, #161616 25%, transparent 25%, transparent 75%, #161616 75%, #161616),\n        linear-gradient(45deg, #161616 25%, transparent 25%, transparent 75%, #161616 75%, #161616);\n    background-position: 0 0, 30px 30px;\n    background-size: 60px 60px;\n}", ""]);

// exports
exports.locals = {
	"giphyNewBlack": "" + require("-!../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css").locals["giphyNewBlack"] + "",
	"background": "_2Whr4fsCK_L_ucjcRRGEh_",
	"checkerboard": "_2b13JmVbRmen7ljiw3f1mS _2Whr4fsCK_L_ucjcRRGEh_"
};