import { renderGrid } from '@giphy/js-components'
import { take, forEach } from 'lodash'
import { IGif } from '@giphy/js-types'
import { throttle } from 'lodash'
import { GiphyFetch } from '@giphy/js-fetch-api'
import { getGifHeight } from '@giphy/js-util'
import { setGridGifHeight } from './embed'

declare global {
    interface Window {
        GIPHY_FE_EMBED_KEY: string
    }
}

const columns = 3
const margin = 15
const gutter = 6

const getWidth = () => {
    return innerWidth - margin * 2
}

const getTallestGif = (gifs: IGif[], width: number) => {
    let tallest = 0
    forEach(gifs, (gif: IGif) => {
        const height = getGifHeight(gif, width)
        if (height > tallest) {
            tallest = height
        }
    })
    return tallest
}

class Grid {
    id: string
    mountNode: HTMLElement
    gf: GiphyFetch
    constructor(id: string, el: HTMLElement) {
        this.mountNode = el
        this.id = id
        const resizeRender = throttle(this.render, 500)
        window.addEventListener('resize', resizeRender as any, false)
        this.gf = new GiphyFetch(window.GIPHY_FE_EMBED_KEY)
    }
    onGifClick = (gif: IGif, e: Event) => {
        e.preventDefault()
        window.open(gif.url, '_blank')
    }
    // fetch first two rows until a user scrolls then fetch more
    fetchGifs = (offset: number) =>
        this.gf.related(this.id, { offset, limit: offset === 0 ? columns * 2 : columns * 4 })
    render = () => {
        const { onGifClick, fetchGifs } = this
        const width = getWidth()
        const onGifsFetched = (gifs: IGif[]) =>
            setGridGifHeight(getTallestGif(take(gifs, 3), (width - margin) / columns))
        renderGrid(
            {
                width,
                onGifClick,
                onGifsFetched,
                fetchGifs,
                user: {},
                columns,
                gutter,
            },
            this.mountNode
        )
    }
}

export default Grid
