exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css"), undefined);
exports.i(require("-!../../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/buttons.css"), undefined);

// module
exports.push([module.id, "._3U2CZTXTrtWrYi0ZEGeqyg {\n    background-color: " + require("-!../../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css").locals["giphyNewBlack"] + ";\n    display: inline-block;\n    font-size: 16px;\n    line-height: 40px;\n    padding: 0;\n    text-indent: -1px;\n    vertical-align: top;\n    width: 36px;\n    cursor: pointer;\n}\n", ""]);

// exports
exports.locals = {
	"giphyNewBlack": "" + require("-!../../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css").locals["giphyNewBlack"] + "",
	"button": "" + require("-!../../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/buttons.css").locals["button"] + "",
	"share": "_3U2CZTXTrtWrYi0ZEGeqyg " + require("-!../../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/buttons.css").locals["button"] + " ss-send"
};