exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/buttons.css"), undefined);

// module
exports.push([module.id, "._1HkEGPpE9GWxC24KDfMDtS {\n    display: inline-block;\n    margin-left: 4px;\n    padding: 0;\n    vertical-align: top;\n    width: 36px;\n}\n\n._2VeDI0mfu7HiLdtoPcFe0K {\n    color: #fff;\n    display: inline-block;\n    font-size: 20px;\n    height: 36px;\n    line-height: 42px;\n    text-align: center;\n    text-indent: -1px;\n    vertical-align: top;\n    width: 36px;\n}\n\n._1GV5lyRcwb0uLlC7XoJivt {\n    background-color: #E42C26;\n}\n\n.cMlLKVKhsHFAMfNL3fw-d {\n    background-color: #00adef;\n}", ""]);

// exports
exports.locals = {
	"button": "" + require("-!../../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/buttons.css").locals["button"] + "",
	"mediaButton": "_1HkEGPpE9GWxC24KDfMDtS " + require("-!../../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/buttons.css").locals["button"] + "",
	"mediaIcon": "_2VeDI0mfu7HiLdtoPcFe0K",
	"youtube": "_1GV5lyRcwb0uLlC7XoJivt _2VeDI0mfu7HiLdtoPcFe0K ss-social ss-youtube",
	"vimeo": "cMlLKVKhsHFAMfNL3fw-d _2VeDI0mfu7HiLdtoPcFe0K ss-social ss-vimeo"
};