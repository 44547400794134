import appendQuery from 'append-query'
import tsml from 'tsml'
import css from './attribution.css'
import { sanitize } from 'dompurify'

export default function (user, trackingQueryString) {
    if (!user || !user.is_public || user.suppress_chrome) {
        return ''
    }
    const { profile_url, avatar_url, display_name, username, attribution_display_name, is_verified } = user
    const avatar = `<span class="${css.avatar}" style="background-image:url(${avatar_url})"></span>`
    const name = attribution_display_name || display_name || username
    const verifiedBadge = is_verified
        ? `<img src="/static/img/embeds/verified-badge.svg" class="${css.verifiedBadge}"></img>`
        : ''
    return tsml`<a class="${css.attribution}" href="${appendQuery(profile_url, trackingQueryString)}" target="_blank">
            ${avatar_url ? avatar : ''}
            <div class="${css.user}">
                <div class="${css.displayName}">${sanitize(name)}</div>
                <div class="${css.username}">@${sanitize(username.toLowerCase())}</div> ${verifiedBadge}
            </div>
        </a>`
}
