exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css"), undefined);

// module
exports.push([module.id, "._2vYxOwTQA6iyw70tC9IvF {\n    position: relative;\n    color: " + require("-!../../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css").locals["giphyWhite"] + ";\n    display: flex;\n    height: 40px;\n    text-decoration: none;\n    vertical-align: top;\n    align-items: center;\n    text-transform: none;\n    flex: 1;\n}\n\n.zDpl0KErkBParz2xHj-JC {\n    background-position: center center;\n    background-repeat: no-repeat;\n    background-size: cover;\n    display: inline-block;\n    height: 40px;\n    margin-right: 8px;\n    vertical-align: top;\n    width: 40px;\n}\n\n._3GnxDX7SIAEyZdq0MhhT3I {\n    max-width: 140px;\n    line-height: 18px;\n    vertical-align: top;\n    white-space: nowrap;\n    text-align: left;\n}\n\n._1_ahUPJUi79BowJfSivl-4 {\n    font-weight: 900;\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n\n.Ix19cIyoy7Cb87VtC391M {\n    display: inline-block;\n    font-weight: 700;\n    overflow: hidden;\n    max-width: 130px;\n    text-overflow: ellipsis;\n}\n._2gmouAgl01AVqY9mAX0T5h {\n    width: 14px;\n    height: 18px;\n    vertical-align: top;\n    padding-left: 2px;\n}\n", ""]);

// exports
exports.locals = {
	"colors": "'shared/css/colors.css'",
	"giphyWhite": "" + require("-!../../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css").locals["giphyWhite"] + "",
	"attribution": "_2vYxOwTQA6iyw70tC9IvF",
	"avatar": "zDpl0KErkBParz2xHj-JC",
	"user": "_3GnxDX7SIAEyZdq0MhhT3I",
	"displayName": "_1_ahUPJUi79BowJfSivl-4",
	"username": "Ix19cIyoy7Cb87VtC391M",
	"verifiedBadge": "_2gmouAgl01AVqY9mAX0T5h"
};