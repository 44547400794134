exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../node_modules/postcss-loader/lib/index.js!../../shared/css/global-fonts.css"), "");
exports.i(require("-!../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css"), undefined);
exports.i(require("-!../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../node_modules/postcss-loader/lib/index.js!shared/css/typography.css"), undefined);

// module
exports.push([module.id, "body {\n        color: " + require("-!../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css").locals["giphyWhite"] + ";\n        font-family: " + require("-!../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../node_modules/postcss-loader/lib/index.js!shared/css/typography.css").locals["bodyFont"] + ";\n        font-size: 14px;\n    }\n\n* {\n        -webkit-font-smoothing: antialiased;\n        -moz-osx-font-smoothing: grayscale;\n    }\n\n.embed[data-ready='true'] {\n        background: transparent;\n    }\n\nh1,\n    h2,\n    h3,\n    h4,\n    h5,\n    a,\n    p,\n    span,\n    *[data-label] {\n        font-size: 14px;\n        text-transform: uppercase;\n    }\n", ""]);

// exports
exports.locals = {
	"colors": "\"shared/css/colors.css\"",
	"giphyWhite": "" + require("-!../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css").locals["giphyWhite"] + "",
	"typography": "\"shared/css/typography.css\"",
	"bodyFont": "" + require("-!../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../node_modules/postcss-loader/lib/index.js!shared/css/typography.css").locals["bodyFont"] + ""
};