exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".QPOqdnK3YcwPvVWrT1JAx:active {\n        opacity: 0.9;\n    }\n    .QPOqdnK3YcwPvVWrT1JAx svg {\n        display: block;\n        height: 20px;\n        width: auto;\n    }\n\n._3UOMCEIXcXsKkhSikKpTpj {\n    display: flex;\n    position: absolute;\n    top: 0;\n    left: 0;\n    transition: transform 0.3s ease-out;\n    padding: 12px 15px;\n    transform: translate3d(0, -60px, 0);\n    align-items: center;\n    justify-content: space-between;\n    width: calc(100% - 90px);\n}\n", ""]);

// exports
exports.locals = {
	"logo": "QPOqdnK3YcwPvVWrT1JAx",
	"header": "_3UOMCEIXcXsKkhSikKpTpj"
};