exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css"), undefined);
exports.i(require("-!../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/buttons.css"), undefined);
exports.i(require("-!../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/fonts.css"), undefined);

// module
exports.push([module.id, "._3wTEnPwRXOXkx4GTG6MZrs {\n    background: #000;\n    height: 100%;\n    position: relative;\n    width: 100%;\n}\n\n._3wTEnPwRXOXkx4GTG6MZrs:hover .bAoJrDdT-3VxpV1YsYtsE {\n        opacity: 1;\n    }\n\n._1ZpCKbITk7SMdF4fjnqZMB {\n    display: block;\n    height: 100%;\n    position: relative;\n    width: 100%;\n    z-index: 1;\n}\n\n.ZVSbBIRed-c9ni6vhAkuM {\n    color: " + require("-!../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css").locals["giphyWhite"] + ";\n    font-family: " + require("-!../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/fonts.css").locals["titleFont"] + ";\n    font-size: 12px;\n    position: absolute;\n    opacity: 0;\n    right: 0;\n    z-index: 2;\n    transition: opacity .3s ease-out;\n}\n\n.ZVSbBIRed-c9ni6vhAkuM:before {\n        font-size: 12px;\n        display: inline-block;\n        margin-right: 2px;\n        vertical-align: middle;\n    }\n\n.bAoJrDdT-3VxpV1YsYtsE {\n    bottom: 0;\n}\n._3GuUKicTsc2ttpfl_FGdXn {\n    bottom: 45px;\n}\n._2DcJFbAAAqAkTZnQ0d6K-O {\n    bottom: 45px;\n}\n\n._2279_9QUQBNxkBTPogR23Q {\n    background: " + require("-!../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css").locals["giphyNewBlack"] + ";\n    bottom: 60px;\n    pointer-events: none;\n    right: 8px;\n}\n\ndiv[data-showbacktogif=\"true\"] ._2279_9QUQBNxkBTPogR23Q {\n        opacity: 1;\n        pointer-events: auto;\n    }", ""]);

// exports
exports.locals = {
	"colors": "\"shared/css/colors.css\"",
	"giphyWhite": "" + require("-!../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css").locals["giphyWhite"] + "",
	"giphyDarkGrey": "" + require("-!../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css").locals["giphyDarkGrey"] + "",
	"giphyNewBlack": "" + require("-!../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/colors.css").locals["giphyNewBlack"] + "",
	"button": "" + require("-!../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/buttons.css").locals["button"] + "",
	"titleFont": "" + require("-!../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/fonts.css").locals["titleFont"] + "",
	"container": "_3wTEnPwRXOXkx4GTG6MZrs",
	"back": "bAoJrDdT-3VxpV1YsYtsE ZVSbBIRed-c9ni6vhAkuM " + require("-!../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/buttons.css").locals["button"] + " ss-navigateleft",
	"embed": "_1ZpCKbITk7SMdF4fjnqZMB",
	"backBase": "ZVSbBIRed-c9ni6vhAkuM " + require("-!../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/buttons.css").locals["button"] + " ss-navigateleft",
	"youtubeback": "_3GuUKicTsc2ttpfl_FGdXn bAoJrDdT-3VxpV1YsYtsE ZVSbBIRed-c9ni6vhAkuM " + require("-!../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/buttons.css").locals["button"] + " ss-navigateleft",
	"vimeoback": "_2DcJFbAAAqAkTZnQ0d6K-O bAoJrDdT-3VxpV1YsYtsE ZVSbBIRed-c9ni6vhAkuM " + require("-!../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/buttons.css").locals["button"] + " ss-navigateleft",
	"mobileback": "_2279_9QUQBNxkBTPogR23Q ZVSbBIRed-c9ni6vhAkuM " + require("-!../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/buttons.css").locals["button"] + " ss-navigateleft"
};