import appendQuery from 'append-query'

let isApiLoaded = false

const refreshYoutubePlayer = () => {
    const player = new global.YT.Player('media-iframe', {
        events: {
            onReady: ({ target }) => {
                target.mute()
            },
            onStateChange: ({ target }) => {
                const state = target.getPlayerState()
                global.showBackToGif(state === 0 || state === 2)
            },
        },
    })
    return player
}

const loadApi = () => {
    if (isApiLoaded) {
        refreshYoutubePlayer()
        return
    }

    const tag = document.createElement('script')
    tag.id = 'youtube-api'
    tag.src = 'https://www.youtube.com/iframe_api'

    const firstScriptTag = document.getElementsByTagName('script')[0]
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)

    global.onYouTubeIframeAPIReady = refreshYoutubePlayer

    isApiLoaded = true
}

export const getUrl = ({ asset_uid, start }) =>
    appendQuery(`https://www.youtube.com/embed/${asset_uid}`, {
        autoplay: 1,
        loop: 1,
        playsinline: 1,
        fs: 0,
        rel: 0,
        volume: 0,
        enablejsapi: 1,
        start: start ? Math.max(start - 1, 0) : 0,
    })

export default {
    init: loadApi,
    getUrl,
}
