import Vimeo from '@vimeo/player'
import appendQuery from 'append-query'
import { giphyBlue } from 'shared/css/colors.css'

const createPlayer = () => {
    const player = new Vimeo('media-iframe')
    player.on('play', () => {
        global.showBackToGif(false)
    })
    player.on('pause', () => {
        global.showBackToGif(true)
    })
    player.on('ended', () => {
        global.showBackToGif(true)
    })
    player.setVolume(0)
}

export const getUrl = ({ asset_uid }) =>
    appendQuery(`https://player.vimeo.com/video/${asset_uid}`, {
        autoplay: true,
        byline: false,
        color: giphyBlue,
        loop: false,
        title: false,
    })

export default {
    init: createPlayer,
    getUrl,
}
