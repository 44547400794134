import css from './media-button.css'

const ALLOWED_PROVIDERS = ['youtube', 'vimeo']

export default function (media) {
    if (!media || !media.provider_name || ALLOWED_PROVIDERS.indexOf(media.provider_name) < 0) {
        return ''
    }

    const { provider_name } = media
    return `<div class="${css.mediaButton}"
            data-haslabel>
            <div class="${css[provider_name]}"></div>
            <div data-label>Watch</div>
        </div>`
}
