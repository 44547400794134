import appendQuery from 'append-query'
import { openPopup } from 'shared/util/gif-social'
import footerTemplate, {
    GRID_HITTING_HEADER_ID,
    GRID_BUTTON_ACTIVE_ID,
    GRID_ACTIVE_MARKER_ID,
    GRID_HEADER_ID,
} from './footer'
import gifTemplate from './gif'
import shareTemplate from './share'
import headerTemplate from './header'
import backgroundTemplate from './background'
import css from './embed.css'
import observeScroll from './observe-scroll'
import { relatedGrid } from '../main'

const GRID_BUTTON_CONTAINER_ID = 'grid-button-container'
const EMBED_ID = 'giphy-embed'
const GRID_GRADIENT_ID = 'grid-gradient'

let isHover = false
let isShareOpen = false
let isPaused = false
let isMobileFooterOpen = false
let clipTimeout = null
let addedEvent

let gridActive = false
let scrollEvent = false
const footerHeaderHeight = 72 // 64 plus a little spacing
let gridGifHeight = 0
export const setGridGifHeight = (height) => {
    const init = gridGifHeight === 0
    gridGifHeight = height
    if (init) global.showHover(isHover)
}
const onGridScrolled = (dir, type) => {
    if (!scrollEvent) {
        scrollEvent = true
    }
    if (dir === 'bottom' && type === 'leave') {
        gridActive = false
    } else {
        gridActive = true
    }
    const embed = document.getElementById(EMBED_ID)
    embed.dataset.gridActive = gridActive
}

const onGridHittingHeader = (dir, type) => {
    let headerHidden = false
    if (dir === 'top') {
        headerHidden = type === 'leave'
    }
    const embed = document.getElementById(EMBED_ID)
    embed.dataset.headerHidden = headerHidden
}

const onGridTopScrolled = (dir, type, ratio) => {
    if (isHover && dir === 'top' && (type === 'leave' || type === 'enter')) {
        document.getElementById(GRID_BUTTON_CONTAINER_ID).style.transform = `translate3d(0, -${100 - ratio * 100}px, 0)`
        document.getElementById(GRID_GRADIENT_ID).style.transform = `translate3d(0, ${50 + ratio * 50}%, 0)` // 100% to 50%
    }
    if (isHover && dir === 'bottom' && type === 'leave') {
        document.getElementById(GRID_BUTTON_CONTAINER_ID).style.transform = `translate3d(0, 0, 0)`
    }
}
let fetched = false
global.showHover = (isOn) => {
    if (global.isMobile) {
        return
    }
    const embed = document.getElementById(EMBED_ID)
    const footer = document.getElementById('footer-container')
    if (!fetched) {
        fetched = true
        relatedGrid.render()
    }
    // showHover should never be called on mobile, but it happens out in the wild somehow
    const gridMarker = document.getElementById(GRID_ACTIVE_MARKER_ID)
    if (gridMarker) {
        gridMarker.style.top = `${getGridPreviewHeight() + 10}px`
        // when we have related gifs
        if (gridGifHeight > 0) {
            // show the RELATED GIFS text after we have gifs
            document.getElementById(GRID_HEADER_ID).style.opacity = 1
            document.getElementById(GRID_ACTIVE_MARKER_ID).style.display = 'block'
            document.getElementById(GRID_BUTTON_ACTIVE_ID).style.display = 'block'
            if (!addedEvent && !global.isMobile) {
                addedEvent = true
                observeScroll(document.getElementById(GRID_ACTIVE_MARKER_ID), onGridScrolled)
                observeScroll(document.getElementById(GRID_HITTING_HEADER_ID), onGridHittingHeader)
                observeScroll(document.getElementById(GRID_BUTTON_ACTIVE_ID), onGridTopScrolled, 10)
            }
        }
    }
    isHover = isOn
    footer.style.overflowY = isHover ? 'auto' : 'hidden'
    if (isHover) {
        embed.dataset.hover = isHover
        if (!isShareOpen) {
            const dataFooter = document.querySelector('div[data-footer]')
            if (dataFooter) {
                dataFooter.style.transform = `translate3d(0, -${getGridPreviewHeight()}px, 0)`
                dataFooter.style.opacity = 1
            }
        }
    }

    clearTimeout(clipTimeout)
    clipTimeout = setTimeout(
        () => {
            embed.dataset.clipit = isHover
            embed.dataset.hover = isHover
            if (!isHover) {
                resetFooter()
                embed.dataset.gridActive = false
                if (footer.scroll) {
                    footer.scroll(0, 0)
                } else {
                    footer.scrollTop = 0
                }
            }
        },
        isHover ? 150 : gridActive ? 1000 : 70
    )
}

global.toggleShareMenu = (isClose) => {
    const embed = document.getElementById(EMBED_ID)
    isShareOpen = isClose ? false : !isShareOpen
    embed.dataset.shareopen = isShareOpen

    if (isShareOpen) {
        resetFooter()
    }
}

global.togglePlayPause = (button) => {
    const gif = document.getElementById('gif')
    isPaused = !isPaused
    if ('pause' in gif) {
        isPaused ? gif.pause() : gif.play()
    } else {
        gif.dataset.paused = isPaused
    }
    button.dataset.paused = isPaused
}

global.toggleMobileFooter = () => {
    const embed = document.getElementById(EMBED_ID)
    isMobileFooterOpen = !isMobileFooterOpen
    embed.dataset.mobilefooteropen = isMobileFooterOpen
}

global.openSharePopup = (url, type) => {
    openPopup(url, type)
}

global.continueClick = (url) => {
    window.open(url)
}

const getGridFooter = (url) =>
    global.isMobile
        ? ``
        : `<div class="${css.gridFooterGradient}" id="${GRID_GRADIENT_ID}"></div>
<div class="${css.gridFooter}" id="${GRID_BUTTON_CONTAINER_ID}">
    <div class="${css.cta}" onclick="continueClick('${url}')">Continue Watching on GIPHY</div>
</div>`

function getGridPreviewHeight() {
    return gridGifHeight + footerHeaderHeight
}

function resetFooter() {
    const footer = document.querySelector('div[data-footer]')
    if (footer) {
        footer.style.transform = `translate3d(0, 0, 0)`
        footer.style.opacity = 0
    }
    const gridButtonContainer = document.getElementById(GRID_BUTTON_CONTAINER_ID)
    if (gridButtonContainer) {
        gridButtonContainer.style.transform = `translate3d(0, 0, 0)`
    }
    const gridGradient = document.getElementById(GRID_GRADIENT_ID)
    if (gridGradient) {
        gridGradient.style.transform = `translate3d(0, 100%, 0)`
    }
}

export default function ({ gif, hideVideo, isTwitter, trackingQueryString }, isWebpSupported) {
    isHover = false
    isShareOpen = false
    isPaused = false
    isMobileFooterOpen = false
    return `<div id="${EMBED_ID}"
            class="${global.isMobile ? css.container : css.containerHoverable}"
            data-shareopen="false"
            onmouseover="showHover(true)"
            onmouseout="showHover(false)">
            <div class="${css.wrapper}">
                ${backgroundTemplate(gif.is_sticker)}
                ${gifTemplate(gif, { isWebpSupported, hideVideo, trackingQueryString })}
                ${footerTemplate(gif, { hideVideo, isTwitter, trackingQueryString })}
                ${headerTemplate(appendQuery('https://giphy.com', trackingQueryString))}
                ${getGridFooter(gif.url)}
            </div>
            <div class="${css.cutout}"></div>
            ${shareTemplate(gif, trackingQueryString)}
            <svg class="${css.cutoutSvg}">
                <defs>
                    <clipPath id="cutout">
                        <rect x="-41" y="0" width="100%" height="100%" />
                        <rect x="0" y="41" width="100%" height="100%" />
                    </clipPath>
                </defs>
            </svg>
        </div>`
}
