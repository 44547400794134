import css from './pause.css'

export default function (isTwitter, hasAttribution) {
    if (!isTwitter) {
        return ''
    }

    return `<div class="${hasAttribution ? css.pauseWithAttribution : css.pause}"
            onclick="togglePlayPause(this)"
            data-pausebutton>
            <i class="${css.pauseIcon}"></i>
            <i class="${css.playIcon}"></i>
        </div>`
}
