import youtube from './youtube'
import vimeo from './vimeo'
import css from './media.css'

const embedTypes = {
    youtube,
    vimeo,
}

global.showBackToGif = (isVisible) => {
    const container = document.getElementById('media-container')
    container.dataset.showbacktogif = isVisible || false
}

global.initEmbedPlayer = (provider) => {
    const embed = embedTypes[provider]
    embed && embed.init()
}

export default function ({ gif: { external_media } }) {
    const { provider_name } = external_media
    const embed = embedTypes[provider_name]

    if (!embed) return ''

    return `<div id="media-container" class="${css.container}">
            <iframe id="media-iframe"
                class="${css.embed}"
                src="${embed.getUrl(external_media)}"
                frameborder="0"
                onload="initEmbedPlayer('${provider_name}')"></iframe>
            <div class="${global.isMobile ? css.mobileback : css[provider_name + 'back']}"
                onclick="toggleMediaEmbed(true);">GIF</div>
        </div>`
}
