import appendQuery from 'append-query'
import { getSocialUrls } from 'shared/util/gif-social'
import { getEmbedCode } from 'shared/util/gif-embed'
import css from './share.css'

export default function (gif, trackingQueryString) {
    if (global.isMobile) {
        return ''
    }

    const { facebook, twitter, pinterest } = getSocialUrls(gif)
    const embedCode = getEmbedCode(gif)
    return `<div class="${css.container}" data-sharemenu>
            <div class="${css.section}">
                <a class="${css.favorite}"
                    href="${appendQuery(gif.url, trackingQueryString + '&ref=fave')}"
                    target="_blank"
                    data-animated="false"></a>
                <div class="${css.facebook}" onclick="openSharePopup('${facebook}', 'facebook')"></div>
                <div class="${css.twitter}" onclick="openSharePopup('${twitter}', 'twitter')"></div>
                <div class="${css.pinterest}" onclick="openSharePopup('${pinterest}', 'pinterest')"></div>
            </div>
            <div class="${css.section}">
                <h3 class="${css.heading}">Embed</h3>
                <input class="${css.input}"
                    type="text"
                    value='${embedCode}'
                    readonly="true"
                    spellcheck="false"
                    onclick="this.select();"
                    onfocus="this.select()" />
            </div>
            <div class="${css.section}">
                <h3 class="${css.heading}">Link</h3>
                <input class="${css.input}"
                    type="text"
                    value="${gif.url}"
                    readonly="true"
                    spellcheck="false"
                    onclick="this.select();"
                    onfocus="this.select()" />
            </div>
            <div class="${css.close}" onClick="toggleShareMenu(true)"></div>
        </div>`
}
