import css from './logo.css'
import share from './share-button'

function logo(url) {
    return `<a href="${url}" class="${css.logo}" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73 17">
                <path fill="#FFF" fill-rule="evenodd" d="M14.598 2.823C12.574.777 10.682.337 8.548.337 2.762.337.122 4.473.122 8.5c0 4.025 2.31 8.095 8.404 8.095 2.97 0 5.654-1.056 6.622-3.014V6.74H7.624v3.41h3.894v1.98c-.88.637-2.156.79-2.97.79-3.102 0-4.136-2.44-4.136-4.42 0-3.015 1.628-4.51 4.136-4.51 1.034 0 2.398.285 3.52 1.34l2.53-2.507zm7.348 13.354V.777h-4.334v15.4h4.334zm7.48-11.66v3.828h3.124c1.21 0 1.826-.88 1.826-1.892s-.638-1.936-1.826-1.936h-3.124zm3.124 7.502h-3.124v4.157H25.07V.777h7.48c4.136 0 6.182 2.596 6.182 5.61 0 3.146-2.068 5.588-6.182 5.632zm22.33 4.18V.8h-4.312v5.85h-5.544V.8h-4.356v15.4h4.356v-5.83h5.544v5.83h4.312zM61.216.776H56.31v.176l5.852 9.086v6.137h4.356V10.04L72.634.974V.777h-4.906l-3.322 5.28-3.19-5.28z"/>
            </svg>
        </a>`
}

function header(url) {
    return `<div class="${css.header}" data-header>
                 ${logo(url)}
                 ${share()}
          </div>`
}

export default header
