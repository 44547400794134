import appendQuery from 'append-query'
import { getMp4, getUrl, getWebp } from 'shared/util/gif-extract'
import css from './gif.css'

const getSrcSet = (gif, getRendition, renditions, sizes) =>
    renditions.reduce((acc, name, index) => {
        const rend = getRendition(gif, name)
        if (rend) {
            return acc + `${rend} ${sizes[index]},`
        }
        return acc
    }, '')

const webp = (gif) => {
    const srcSet = getSrcSet(gif, getWebp, ['fixed_width', 'original'], ['200w', '480w'])
    return `<img id="gif"
        class="${css.image}"
        src="${getWebp(gif, 'fixed_width')}"
        srcset="${srcSet}"
        alt="${gif.alt_text || gif.title}"
        sizes="100vw"
        />`
}

const image = (gif) => {
    const srcSet = getSrcSet(gif, getUrl, ['fixed_width', 'downsized_large', 'original'], ['200w', '480w', '500w'])
    return `<img id="gif"
        class="${css.image}"
        src="${getUrl(gif, 'fixed_width')}"
        srcset="${srcSet}"
        alt="${gif.alt_text || gif.title}"
        sizes="100vw"
        />`
}

const mp4 = (gif) =>
    `<video id="gif"
        class="${css.video}"
        src="${getMp4(gif)}"
        autoplay loop muted playsinline>
        <img class="${css.image}" src="${getUrl(gif, 'downsized_large')}" alt="" />
    </video>`

export default function (gif, { isWebpSupported, hideVideo, trackingQueryString }) {
    const { is_sticker, url, external_media } = gif
    const hasExternalMedia = !hideVideo && external_media && external_media.provider_name
    const hasWebp = getWebp(gif, 'fixed_width')
    const hasMp4 = getMp4(gif)
    const useWebp = hasWebp && isWebpSupported
    let rendition = useWebp ? webp : hasMp4 && !global.isMobile && !is_sticker ? mp4 : image
    return `<a class="${css.container}"
            href="${appendQuery(url, trackingQueryString)}"
            target="_blank"
            onclick="${global.isMobile && hasExternalMedia ? 'event.preventDefault();toggleMobileFooter();' : ''}"
            data-gif>
            ${rendition(gif)}
            '<div class="${css.still}"
                style="background-image:url(${getUrl(gif, 'fixed_width_still')})"></div>'
        </a>`
}
