import { checkIfWebP } from '@giphy/js-util'
import 'intersection-observer'
import 'shared/trackjs'
import isMobile from 'shared/util/is-mobile'
import embed from './components/embed'
import Grid from './components/grid'
import media from './components/media'
import './main.css'

let embedElement = null
let embedData = {}
let readyTimeout = null
let isPlayingMedia = false
let isWebpSupported = false
export let relatedGrid
const onAnimationEnd = (el) => {
    clearTimeout(readyTimeout)
    if (el) {
        el.dataset.ready = true
    }
}

const renderGif = () => {
    embedElement.innerHTML = embed(embedData, isWebpSupported)
    if (!global.isMobile) {
        relatedGrid = new Grid(embedData.gif.id, document.getElementById('grid'))
    }
}

const renderMedia = () => {
    embedElement.innerHTML = media(embedData)
}

global.isMobile = isMobile()

global.toggleMediaEmbed = (isClose) => {
    isPlayingMedia = isClose ? false : !isPlayingMedia
    isPlayingMedia ? renderMedia() : renderGif()
}

global.Giphy = global.Giphy || {}
global.Giphy.renderGifEmbed = (el, data) => {
    embedElement = el
    embedData = data
    checkIfWebP.then((isGood) => {
        isWebpSupported = isGood
        renderGif()

        const embedElement = document.getElementById('giphy-embed')
        embedElement.addEventListener('animationend', () => onAnimationEnd(el))
        embedElement.addEventListener('webkitAnimationEnd', () => onAnimationEnd(el))
        readyTimeout = setTimeout(() => onAnimationEnd(el), 700)
    })
}
