// [0.0, 0.2, 0.4, 0.6, 0.8, 1.0] when step is 5.
const thresholdArray = (steps: number) =>
    Array(steps + 1)
        .fill(0)
        .map((_, index) => index / steps || 0)

type SCROLL = 'bottom' | 'top'
type TYPE = 'enter' | 'leave'

// TODO this works for the embed grid, hasn't been tested too thoroughly as a general util
const observeScroll = async (
    el: HTMLElement,
    onScroll: (direction: SCROLL, type: TYPE, ratio: number) => {},
    thresholds = 5
) => {
    // @ts-ignore
    if (!window.IntersectionObserver) {
        await import('intersection-observer')
    }
    let previousY = 0
    let previousRatio = 0
    const handleIntersect = (entries: IntersectionObserverEntry[]) => {
        entries.forEach((entry) => {
            const currentY = entry.boundingClientRect.top
            const currentRatio = entry.intersectionRatio
            const isIntersecting = entry.isIntersecting
            // Scrolling down/up
            if (currentY < previousY) {
                if (currentRatio > previousRatio && isIntersecting) {
                    onScroll('bottom', 'enter', currentRatio)
                } else {
                    onScroll('top', 'leave', currentRatio)
                }
            } else if (currentY > previousY) {
                if (currentRatio < previousRatio) {
                    onScroll('bottom', 'leave', currentRatio)
                } else if (isIntersecting) {
                    onScroll('top', 'enter', currentRatio)
                }
            }
            previousY = currentY
            previousRatio = currentRatio
        })
    }
    const observer = new IntersectionObserver(handleIntersect, {
        threshold: thresholdArray(thresholds),
    })
    observer.observe(el)
}

export default observeScroll
