exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/buttons.css"), undefined);

// module
exports.push([module.id, ".tJoeK6SIXL4GQxtpQ6k20 {\n    position: relative;\n    float: left;\n    font-size: 16px;\n    line-height: 40px;\n    padding: 0;\n    vertical-align: top;\n    width: 36px;\n}\n\n.tJoeK6SIXL4GQxtpQ6k20[data-paused='true'] ._28UjDtdZBQBztiorKtBxKy {\n            display: none;\n        }\n\n.tJoeK6SIXL4GQxtpQ6k20[data-paused='true'] .Y6g77r-hRFlrI4ehgyTfU {\n            display: inline;\n        }\n._3Lx8x0CffrCKl0IFHhsMXu {\n    float: none;\n    left: 15px;\n    position: absolute;\n    top: -36px;\n}\n\n._28UjDtdZBQBztiorKtBxKy {\n}\n\n.Y6g77r-hRFlrI4ehgyTfU {\n    display: none;\n}\n", ""]);

// exports
exports.locals = {
	"button": "" + require("-!../../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/buttons.css").locals["button"] + "",
	"pause": "tJoeK6SIXL4GQxtpQ6k20 " + require("-!../../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/buttons.css").locals["button"] + "",
	"pauseIcon": "_28UjDtdZBQBztiorKtBxKy ss-pause",
	"playIcon": "Y6g77r-hRFlrI4ehgyTfU ss-play",
	"pauseWithAttribution": "_3Lx8x0CffrCKl0IFHhsMXu tJoeK6SIXL4GQxtpQ6k20 " + require("-!../../../../../../../../node_modules/css-loader/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../../node_modules/postcss-loader/lib/index.js!shared/css/buttons.css").locals["button"] + ""
};